import React from "react"
import Helmet from "react-helmet"

import Nav from "../../partials/Nav"
import PageSection from "../../layout/PageSection"
import Footer from "../../partials/Footer"
import PartnerHero from "../../partials/PartnerHero"
import WithThanksTo from "../../partials/WithThanksTo"
import PartnerGrid from "../../components/PartnerGrid"
import { LINKS } from "../../constants"
import CookieBanner from "../../partials/CookieBanner"
import "../../styles.css"

const corporateGridData = [
  {
    imgKey: "justGiving",
    imgAlt: "Just giving",
    description: (
      <p>
        JustGiving is the world’s most trusted platform for online giving. We’re
        enormously proud to be working with a platform that has helped people in
        almost every single country in the world raise over £4.4 billion for
        good causes since they were founded in 2000.
      </p>
    ),
    url: LINKS.JUST_GIVING,
  },
  {
    imgKey: "zipcar",
    imgAlt: "Zipcar",
    description: (
      <p>
        We are excited to be working with the Zipcar community, who put health
        and sustainability at the forefront. As the UK’s biggest and most
        popular car sharing club, join online and get access to over 3,000 cars
        and vans all via their app. They make city living simpler, smarter and
        greener.
      </p>
    ),
    url: LINKS.ZIP_CAR,
  },

  // {
  //   logoSrc: classpassLogo,
  //   imgAlt: "Classpass",
  //   description: (
  //     <>
  //       <p>
  //         Founded in 2013, ClassPass is the largest aggregator of fitness and
  //         wellness brands. ClassPass offers access to 30,000 studios, gyms and
  //         wellness providers, as well as a growing catalog of on-demand and live
  //         streamed digital fitness options.
  //       </p>
  //       <p>
  //         Download the ClassPass app so you can donate directly to Run For
  //         Heroes through their in-app credit system.
  //       </p>
  //     </>
  //   ),
  //   url: LINKS.CLASSPASS,
  // },
  {
    imgKey: "helloFresh",
    imgAlt: "Hello Fresh",
    description: (
      <p>
        We have very important values when it comes to health and wellbeing and
        always look to partner with brands who share those values.  That’s why
        we’re proud to be teaming up with HelloFresh, a recipe box company that
         makes it  simple to cook delicious meals from scratch. Choose your
        favourite dishes from over 30 weekly recipes and they’ll deliver all the
        fresh, pre-portioned ingredients you need to cook them directly to your
        door. It means less time meal-planning and standing in supermarket
        queues, and more time for you to Run for our Heroes.
      </p>
    ),
    url: LINKS.HELLO_FRESH,
  },
]

const Corporate: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Become A Corporate Partner | Run For Heroes</title>
        <meta
          name="description"
          content="Interested in partnering with us? We work with companies across a wide range of industries  who support us achieving our fundraising goals."
        />
        <html lang="en" />
      </Helmet>
      <Nav
        activePath={"/our-partners/corporate"}
        childActivePath={"/our-partners/corporate"}
      />
      <PageSection backgroundColor={"BLUE"}>
        <PartnerHero heading={"Our Partners"} />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <PartnerGrid data={corporateGridData} subCopy={false} />
      </PageSection>
      <PageSection backgroundColor={"BLUE"}>
        <WithThanksTo />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default Corporate
