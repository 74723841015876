import styled from "styled-components"

import { LAYOUT, COLORS, FONT_FAMILIES } from "../constants"

interface IAlignments {
  alignment?: "LEFT" | "CENTER"
  color?: "GREY" | "WHITE" | "BLUE"
}

const ALIGNMENT_MAP = {
  LEFT: "left",
  CENTER: "center",
}

const H1 = styled.h1<IAlignments>`
  font-family: ${FONT_FAMILIES.RFH_REGULAR};
  line-height: 1;
  font-size: 3.2rem;
  text-align: ${({ alignment = "left" }) => ALIGNMENT_MAP[alignment]};
  color: ${({ color = "GREY" }) => COLORS[color]};
  font-weight: normal;
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    font-size: 4.8rem;
    line-height: 1.05;
  }
`

const H2 = styled.h2<IAlignments>`
  font-family: ${FONT_FAMILIES.RFH_REGULAR};
  line-height: normal;
  font-size: 2.4rem;
  font-weight: normal;
  text-align: ${({ alignment = "left" }) => ALIGNMENT_MAP[alignment]};
  color: ${({ color = "GREY" }) => COLORS[color]};
`

const H3 = styled.h3<IAlignments>`
  font-family: ${FONT_FAMILIES.RFH_REGULAR};
  line-height: normal;
  font-size: 2.2rem;
  font-weight: normal;
  text-align: ${({ alignment = "left" }) => ALIGNMENT_MAP[alignment]};
  color: ${({ color = "GREY" }) => COLORS[color]};
`

export { H1, H2, H3 }
