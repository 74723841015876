import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { COLORS, LAYOUT, LINKS } from "../constants"

const DEFAULT_DATA = [
  {
    imgKey: "macmillan",
    imgAlt: "Macmillan cancer support",
    description: (
      <p>
        At Macmillan, we give people with cancer everything we’ve got. If you’re
        diagnosed, your worries are our worries. We will move mountains to help
        you live life as fully as you can. And we don’t stop there. We’re going
        all out to find ever better ways to help people with cancer, helping to
        bring forward the day when everyone gets life-transforming support from
        day one.
      </p>
    ),
    url: LINKS.MACMILLAN,
  },
  {
    imgKey: "greatOrmondStreet",
    imgAlt: "Great Ormond Street",
    description: (
      <p>
        Every day brings new challenges at Great Ormond Street Hospital (GOSH).
        Every day, 618 children and young people from across the UK arrive.
        Every day, doctors and nurses battle the most complex illnesses, and the
        brightest minds come together to achieve pioneering medical
        breakthroughs. And every day is a chance for you to make a difference.
        This extraordinary hospital has always depended on charitable support to
        give seriously ill children the best chance to fulfil their potential. A
        better future for seriously ill children starts here.
      </p>
    ),
    url: LINKS.GREAT_ORMOND_STREET,
  },
  {
    imgKey: "chooseLove",
    imgAlt: "Choose Love",
    description: (
      <p>
        Choose Love does whatever it takes to identify, close and prevent gaps
        in services and protections for refugees and displaced people. We
        elevate the voices and visibility of refugees and are the only funder of
        agile community organisations providing vital support to refugees along
        migration routes globally. In just five years, we have raised $45M to
        provide 1 million refugees with everything from lifesaving search and
        rescue boats to food to legal advice. We are powered by you and by our
        vision - a world that chooses love and justice every day, for everyone.{" "}
        <a
          href={LINKS.CHOOSE_LOVE_INSTAGRAM}
          target={"_blank"}
          rel={"noopener"}
        >
          @chooselove
        </a>
      </p>
    ),
    url: LINKS.CHOOSE_LOVE,
  },
  {
    imgKey: "nhsct",
    imgAlt: "NHSCT",
    description: (
      <p>
        NHS Charities Together is the national charity caring for the NHS. We
        are made up of 241 NHS charity members based with hospitals, mental
        health trusts, ambulance trusts, community health trusts and health
        boards across the UK. The funds raised by people like you enable the
        health service to go above and beyond what would otherwise be possible,
        funding projects to improve services for patients and to help NHS staff
        and volunteers so that they can continue with their vital work.
      </p>
    ),
    url: LINKS.NHS_CHARITIES,
  },
  {
    imgKey: "mentalHealthUk",
    imgAlt: "Mental Health Uk",
    description: (
      <>
        <p>
          1 in 4 of us in the UK has experienced a mental health problem. Mental
          Health UK brings together the heritage and experience of four
          charities who have been supporting people living with mental illness
          for nearly 50 years. Working UK-wide, they deliver national and local
          services, in a person-centred, needs-led and compassionate way.
        </p>
      </>
    ),
    url: LINKS.MENTAL_HEALTH_UK,
  },
  // {
  //   logoSrc: youthSportTrustLogo,
  //   imgAlt: "Youth Sport Trust",
  //   description: (
  //     <p>
  //       Youth Sport Trust are a children’s charity working to ensure every child
  //       enjoys the life-changing benefits that come from play and sport. They
  //       harness the power of sport, physical activity and PE to increase young
  //       people’s life chances through improved wellbeing, healthier lifestyles
  //       and greater attainment. In this way they are helping children to become
  //       schoolready, promoting inclusion and tolerance and giving young people a
  //       sense of belonging.
  //     </p>
  //   ),
  //   url: LINKS.YOUTH_SPORT_TRUST,
  // },
  {
    imgKey: "bhf",
    imgAlt: "BFH",
    description: (
      <p>
        We fund over £100 million of research each year into all heart and
        circulatory diseases and the things that cause them. Heart diseases.
        Stroke. Vascular dementia. Diabetes. They're all connected, and they're
        all under our microscope.
      </p>
    ),
    url: LINKS.BRITISH_HEART_FOUNDATION,
  },
]

interface IPartner {
  description: JSX.Element
  url: string
  imgAlt: string
  imgKey: string
}

interface IProps {
  rest?: object
  data?: Array<IPartner>
  subCopy?: boolean
}

const Root = styled.div`
  max-width: 906px;
  margin: 0 auto;
  padding-top: 20px;
`

const PartnerContainer = styled.div`
  padding-bottom: 35px;
  padding-top: 35px;
  border-bottom: 2px solid ${COLORS.GREY};

  &:last-of-type {
    border-bottom: none;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    display: flex;
  }
`
const CopyContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction column;
`

const Description = styled(BodyText)`
  padding-bottom: 20px;
  p,
  a {
    text-align: center;
  }
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    p,
    a {
      text-align: left;
    }
  }
`

const LogoContainer = styled.a`
  max-width: 300px;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 20px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    max-width: 50%;
    margin: 0;
  }
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    width: 554px;
  }
`

interface ILogo {
  fluid: object
}
const Logo = styled(Img)<ILogo>`
  width: 100%;
  align-self: center;
`

const SubCopy = styled(BodyText)`
  margin-top: 40px;
  margin-bottom: 40px;
`

const PartnerGrid: React.FC<IProps> = ({
  data = DEFAULT_DATA,
  subCopy = true,
  ...rest
}) => {
  const imgData = useStaticQuery(graphql`
    query PartnerGridQuery {
      nhsct: file(relativePath: { eq: "partners/charity/nhsct.png" }) {
        childImageSharp {
          fluid(maxWidth: 554, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      bhf: file(relativePath: { eq: "partners/charity/bhf.png" }) {
        childImageSharp {
          fluid(maxWidth: 554, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mentalHealthUk: file(
        relativePath: { eq: "partners/charity/mental-health-uk.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 554, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      justGiving: file(
        relativePath: { eq: "partners/corporate/just-giving.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 554, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      zipcar: file(relativePath: { eq: "partners/corporate/zipcar.png" }) {
        childImageSharp {
          fluid(maxWidth: 554, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      helloFresh: file(
        relativePath: { eq: "partners/corporate/hello-fresh.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 554, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      macmillan: file(relativePath: { eq: "partners/charity/macmillan.png" }) {
        childImageSharp {
          fluid(maxWidth: 554, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      greatOrmondStreet: file(
        relativePath: { eq: "partners/charity/great-ormond-street.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 554, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      chooseLove: file(
        relativePath: { eq: "partners/charity/choose-love.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 554, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  console.log(imgData)
  return (
    <Root {...rest}>
      {data.map((partner, i) => (
        <PartnerContainer key={i}>
          <LogoContainer href={partner.url}>
            <Logo
              fluid={imgData[partner.imgKey].childImageSharp.fluid}
              alt={partner.imgAlt}
            />
          </LogoContainer>
          <CopyContainer>
            <Description type={"DARK"}>{partner.description}</Description>
          </CopyContainer>
        </PartnerContainer>
      ))}
      {subCopy && (
        <SubCopy type={"DARK"} alignment={"CENTER"}>
          <p>
            During live campaign collaborations, 5% of the total amount raised
            by each partner is given back to Run For Heroes to further its
            charitable purposes.
          </p>
          <p>
            If you’d like more information about this, or would like to become a
            charity partner please <Link to={"/contact-us"}>contact us</Link>.
          </p>
        </SubCopy>
      )}
    </Root>
  )
}

export default PartnerGrid
