import React from "react"
import styled from "styled-components"

import { H1 } from "../components/Heading"
import BodyText from "../components/BodyText"
import { LAYOUT, FONT_FAMILIES } from "../constants"

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  flex-direction: column;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    min-height: 480px;
  }
`

const Heading = styled(H1)`
  font-family: ${FONT_FAMILIES.RFH_OUTLINE};
  font-size: 5rem;
  margin-bottom: 20px;
  max-width 1052px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    font-size: 11.4rem;
  }
`

const SubCopy = styled(BodyText)`
  max-width: 750px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    p {
      font-size: 2rem;
    }
  }
`

interface IPartnerHero {
  heading: string
  subCopy?: string
  rest?: object
}

const PartnerHero: React.FC<IPartnerHero> = ({ heading, subCopy, ...rest }) => {
  return (
    <Root {...rest}>
      <Heading color={"WHITE"} alignment={"CENTER"}>
        {heading}
      </Heading>
      {subCopy && (
        <SubCopy alignment={"CENTER"}>
          <p>{subCopy}</p>
        </SubCopy>
      )}
    </Root>
  )
}

export default PartnerHero
