import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { H2 } from "../components/Heading"
import { LAYOUT, LINKS } from "../constants"
import BodyText from "../components/BodyText"
import twitterIcon from "../assets/img/footer/icon-twitter.svg"
import facebookIcon from "../assets/img/footer/icon-fb.svg"
import instagramIcon from "../assets/img/footer/icon-insta.svg"
import tikTokIcon from "../assets/img/footer/icon-tik-tok.svg"

const IMG_DATA = [
  { title: "Strava", key: "strava", url: LINKS.STRAVA },
  { title: "ClassPass", key: "classpass", url: LINKS.CLASSPASS },
  { title: "VitaCoco", key: "vitaCoco", url: LINKS.VITA_COCO },
  {
    title: "Core collective",
    key: "coreCollective",
    url: LINKS.CORE_COLLECTIVE,
  },
  // { title: "Couch to 5k", key: "couchTo5k", url: LINKS.COUCH_TO_5K },
  { title: "Days", key: "days", url: LINKS.DAYS },
  // {
  //   title: "Nuffield health",
  //   key: "nuffieldHealth",
  //   url: LINKS.NUFFIELD_HEALTH,
  // },

  // { title: "Dishoom", key: "dishoom", url: LINKS.DISHOOM },
  { title: "Fiit", key: "fiit", url: LINKS.FIIT },
  {
    title: "Boom Cycle",
    key: "boomCycle",
    url: LINKS.BOOM_CYCLE,
  },
  // { title: "Rapha", key: "rapha", url: LINKS.RAPHA },
  { title: "Runners need", key: "runnersNeed", url: LINKS.RUNNERS_NEED },

  {
    title: "Create",
    key: "create",
    url: LINKS.CREATE,
  },
  { title: "Mac roberts", key: "macRoberts", url: LINKS.MAC_ROBERTS },
  // { title: "Sports Wales", key: "sportsWales", url: LINKS.SPORT_WALES },
  {
    title: "Sport Scotland",
    key: "sportScotland",
    url: LINKS.SPORT_SCOTLAND,
  },
  { title: "BOLD", key: "bold", url: LINKS.BOLD },
  { title: "Lets do this", key: "letsDoThis", url: LINKS.LETS_DO_THIS },
  { title: "Route 40", key: "route40", url: LINKS.ROUTE_40 },
  { title: "Find a race", key: "findARace", url: LINKS.FIND_A_RACE },
  { title: "Wild", key: "wild", url: LINKS.WILD },
  { title: "Pip&Nut", key: "pipNut", url: LINKS.PIP_NUT },
  { title: "Tala", key: "tala", url: LINKS.TALA },
  {
    title: "Asics London 10k",
    key: "asicsLondon10k",
    url: LINKS.ASICS_LONDON_10K,
  },
  { title: "bmf", key: "bmf", url: LINKS.BMF },
  { title: "Jimmy's", key: "jimmys", url: LINKS.JIMMYS },
  { title: "Kobox", key: "kobox", url: LINKS.KOBOX },
  { title: "Tenzing", key: "tenzing", url: LINKS.TENZING },
  { title: "Tribe", key: "tribe", url: LINKS.TRIBE },
  { title: "Barrecore", key: "barrecore", url: LINKS.BARRECORE },
]

interface ISocialItems {
  name: string
  image: string
  url: string
}

const SOCIAL_ITEMS: ISocialItems[] = [
  {
    name: "Twitter",
    image: twitterIcon,
    url: LINKS.TWITTER,
  },
  {
    name: "Facebook",
    image: facebookIcon,
    url: LINKS.FACEBOOK,
  },
  {
    name: "Instagram",
    image: instagramIcon,
    url: LINKS.INSTAGRAM,
  },
  {
    name: "TikTok",
    image: tikTokIcon,
    url: LINKS.TIKTOK,
  },
]

const Root = styled.div`
  max-width: 1270px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`
const LogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

interface ILogo {
  fluid: object
}

const Logo = styled(Img)<ILogo>`
  width: 100px;
  align-self: center;
  margin-bottom: 30px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    width: 242px;
  }
`

const Heading = styled(H2)`
  margin-bottom: 30px;
`

const SocialContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;

  a {
    margin: 0 15px;
  }

  img {
    width: 75px;
  }
`

interface IWithThanksTo {
  rest?: object
}

const WithThanksTo: React.FC<IWithThanksTo> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query WithThanksToQuery {
      classpass: file(relativePath: { eq: "withThanksTo/classpass.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      vitaCoco: file(relativePath: { eq: "withThanksTo/vita-coco.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      coreCollective: file(
        relativePath: { eq: "withThanksTo/core-collective.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      couchTo5k: file(relativePath: { eq: "withThanksTo/couch-to-5k.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      days: file(relativePath: { eq: "withThanksTo/days.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      nuffieldHealth: file(
        relativePath: { eq: "withThanksTo/nuffield-health.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      dishoom: file(relativePath: { eq: "withThanksTo/dishoom.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      fiit: file(relativePath: { eq: "withThanksTo/fiit.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      boomCycle: file(relativePath: { eq: "withThanksTo/boom-cycle.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rapha: file(relativePath: { eq: "withThanksTo/rapha.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      runnersNeed: file(relativePath: { eq: "withThanksTo/runners-need.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      create: file(relativePath: { eq: "withThanksTo/create.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      macRoberts: file(relativePath: { eq: "withThanksTo/mac-roberts.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sportsWales: file(relativePath: { eq: "withThanksTo/sports-wales.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      strava: file(relativePath: { eq: "withThanksTo/strava.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sportScotland: file(
        relativePath: { eq: "withThanksTo/sport-scotland.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      bold: file(relativePath: { eq: "withThanksTo/bold.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      letsDoThis: file(relativePath: { eq: "withThanksTo/lets-do-this.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      route40: file(relativePath: { eq: "withThanksTo/route-40.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      route40: file(relativePath: { eq: "withThanksTo/route-40.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      findARace: file(relativePath: { eq: "withThanksTo/find-a-race.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tala: file(relativePath: { eq: "withThanksTo/tala.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pipNut: file(relativePath: { eq: "withThanksTo/pip-nut.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      wild: file(relativePath: { eq: "withThanksTo/wild.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      asicsLondon10k: file(
        relativePath: { eq: "withThanksTo/asics-london-10k.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      bmf: file(relativePath: { eq: "withThanksTo/bmf.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      jimmys: file(relativePath: { eq: "withThanksTo/jimmys.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      kobox: file(relativePath: { eq: "withThanksTo/kobox.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tenzing: file(relativePath: { eq: "withThanksTo/tenzing.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tribe: file(relativePath: { eq: "withThanksTo/tribe.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      barrecore: file(relativePath: { eq: "withThanksTo/barrecore.png" }) {
        childImageSharp {
          fluid(maxWidth: 242, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <Heading color={"WHITE"} alignment={"CENTER"}>
        With thanks to
      </Heading>

      <LogosContainer>
        {IMG_DATA.map((data, i) => {
          return data.url ? (
            <a href={data.url} target={"_blank"} rel={"noopener"}>
              <Logo
                loading={"eager"}
                fadeIn={false}
                key={i}
                fluid={imgData[data.key].childImageSharp.fluid}
                alt={data.title}
              />
            </a>
          ) : (
            <Logo
              loading={"eager"}
              fadeIn={false}
              key={i}
              fluid={imgData[data.key].childImageSharp.fluid}
              alt={data.title}
            />
          )
        })}
      </LogosContainer>
      <SocialContainer>
        {SOCIAL_ITEMS.map(socialItem => (
          <Link
            key={socialItem.name}
            to={socialItem.url}
            target={"_blank"}
            rel={"noopener"}
          >
            <img alt={socialItem.name} src={socialItem.image} />
          </Link>
        ))}
      </SocialContainer>
      <BodyText alignment={"CENTER"}>
        <p>
          Want to be a partner? <Link to={"/contact-us"}>Contact us</Link>
        </p>
      </BodyText>
    </Root>
  )
}

export default WithThanksTo
